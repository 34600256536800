import * as React from "react";
import styled from "@emotion/styled";
import NavigationBar from "../components/NavigationBar";
import { defaultColorTheme } from "../constants/color-themes";
import ColorTheme from "../types/color-theme";
import Footer from "../components/Footer";
import GlobalStyler from "../components/stylers/GlobalStyler";
import defaultContentStyles from "../components/stylers/default-content-styles";
import { useStore } from "../components/StoreProvider";
import { css } from "@emotion/react";
import {
  safeAreaInsetTop,
  safeAreaInsetBottom,
  safeAreaInsetLeft,
  safeAreaInsetRight,
} from "../constants/sizes";
import { safeAreaBlocker } from "../constants/z-indices";

// this container is required so the min-height set in the child container works in IE
const InternetExplorerMinHeightFixContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SafeAreaBlocker = ({ colorTheme }) => (
  <>
    <div
      css={css`
        position: fixed;
        z-index: ${safeAreaBlocker};
        background-color: ${colorTheme.colors[1]};
        top: 0;
        left: 0;
        right: 0;
        height: ${safeAreaInsetTop};
      `}
    />
    <div
      css={css`
        position: fixed;
        z-index: ${safeAreaBlocker};
        background-color: ${colorTheme.colors[1]};
        bottom: 0;
        left: 0;
        right: 0;
        height: ${safeAreaInsetBottom};
      `}
    />
    <div
      css={css`
        position: fixed;
        z-index: ${safeAreaBlocker};
        background-color: ${colorTheme.colors[1]};
        bottom: 0;
        top: 0;
        left: 0;
        width: ${safeAreaInsetLeft};
      `}
    />
    <div
      css={css`
        position: fixed;
        z-index: ${safeAreaBlocker};
        background-color: ${colorTheme.colors[1]};
        bottom: 0;
        top: 0;
        right: 0;
        width: ${safeAreaInsetRight};
      `}
    />
  </>
);

const OuterContainer = styled.div<{ isFullscreenContent: boolean }>`
  min-height: calc(100vh - ${safeAreaInsetTop} - ${safeAreaInsetBottom});
  ${(props) =>
    props.isFullscreenContent &&
    `max-height: calc(100vh - ${safeAreaInsetTop} - ${safeAreaInsetBottom});`}

  display: flex;
  flex-direction: column;
  align-items: center;

  ${/* z-index is required so navigationBar z-index has an effect in IE11 */ ""}
  z-index: 0;
`;

const InnerContainer = styled.div<{ isFullscreenContent: boolean }>`
  flex: auto;
  max-width: 1000px;
  width: 100%;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.isFullscreenContent &&
    `
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
  `}
`;

export const ColorThemeContext = React.createContext(defaultColorTheme);

export const anchorLinkTopId = "top";

interface BaseLayoutProps {
  colorTheme: ColorTheme;
  headerBarTitleText: string;
  headerBarTitleLinkUrl?: string;
  isFullscreenContent?: boolean;
  children?: React.ReactNode;
}

const DefaultLayout: React.FunctionComponent<BaseLayoutProps> = (props) => {
  const capacitorStore = useStore().capacitorStore;

  React.useEffect(() => {
    if (capacitorStore.platform === "android") {
      capacitorStore.changeStatusBarColor(props.colorTheme.colors[1]);
    }
  }, [capacitorStore]);

  return (
    <ColorThemeContext.Provider value={props.colorTheme}>
      <GlobalStyler>
        <SafeAreaBlocker colorTheme={props.colorTheme} />
        <InternetExplorerMinHeightFixContainer>
          <OuterContainer
            id={anchorLinkTopId}
            isFullscreenContent={props.isFullscreenContent}
          >
            <NavigationBar
              headerBarTitleText={props.headerBarTitleText}
              headerBarTitleLinkUrl={props.headerBarTitleLinkUrl}
            />
            <InnerContainer
              css={defaultContentStyles}
              isFullscreenContent={props.isFullscreenContent}
            >
              {props.children}
            </InnerContainer>
            {!props.isFullscreenContent && <Footer />}
          </OuterContainer>
        </InternetExplorerMinHeightFixContainer>
      </GlobalStyler>
    </ColorThemeContext.Provider>
  );
};

export default DefaultLayout;
